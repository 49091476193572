<template>
  <div id="vm-cola-home">
    <IdleBanner />
  </div>
</template>

<script>
import ls from '@/components/helpers/local-storage';

export default {
  name: 'vmColaHome',
  components: {
    IdleBanner: () => import('@/components/vm-cola/snippets/idle-banner/idle-banner'),
  },
  created() {
    this.resetCart();
  },
  methods: {
    resetCart() {
      Promise.all([
        ls.remove('guest_cart_id'),
        ls.remove('latest_cart_id'),
        ls.remove('is_guest'),
        ls.remove('cart'),
        this.$store.commit('SET_CART_ID', null),
        this.$store.commit('SET_SELECTED_POINT', 0),
        this.$store.dispatch('resetCart', 0),
      ]);
    },
  },
};
</script>
